import { createTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    //tan
    primary: {
      main: '#d7ccc8',
    },
    //light red
    secondary: {
      main: '#ef5350',
    },
    //white
    background: {
      default: '#fff',
    },
  },
});

export default theme;