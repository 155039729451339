import React from 'react';
import { Link } from "gatsby"
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import theme from '../theme';
import { WindowLocation } from '../types/PageProps';

const primary = theme.palette.primary.main;

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: primary,
    marginTop: theme.spacing(8),
    padding: theme.spacing(6, 0),
  },
}));


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link style={{textDecoration: 'none', color: 'grey'}} to="/">
        Dolores Hair Designers
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}



export default function Footer(props: { location?: WindowLocation<unknown> }) {
  const classes = useStyles();

  return (
    <footer className={classes.footer} style={props.location?.pathname === '/gallery' ? {marginTop: 1800} : undefined}>
      <Copyright />
    </footer>
  );
};