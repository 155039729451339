import React from "react"
import { Grid, Container, Grow, Button } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "gatsby"
import Logo from '../images/Dolores-and-Co-Black (2).png';

const useStyles = makeStyles({
  button: {
    whiteSpace: 'nowrap',
    fontFamily: 'Raleway',
    fontWeight: 'bold',
    height: 50
  },
});

const Header = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="xl">
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <Link to="/">
            <img style={{height: 200}} alt="salon" src={Logo} />
          </Link>
        </Grid>
      </Grid>
      <Grid container spacing={1} justifyContent="center" alignItems="center" style={{marginTop: 50}}>
        <Grid item md={2} sm={5} xs={10} >
          <Grow in={true}>
            <Button color='primary' variant="contained" component={Link} to='/dolores' className={classes.button} fullWidth size="small">
              Meet Dolores
            </Button>
          </Grow>
        </Grid>
        <Grid item md={2} sm={5} xs={10} >
          <Grow in={true} timeout={1000}>
            <Button color='primary' variant="contained" component={Link} to="/join" className={classes.button} fullWidth size="small">
              Join our Team
            </Button>
          </Grow>
        </Grid>
        <Grid item md={2} sm={5} xs={10} >
          <Grow in={true} timeout={1500}>
            <Button color='primary' variant="contained" component={Link} to='/about' className={classes.button} fullWidth size="small">
              About Us
            </Button>
          </Grow>
        </Grid>
        <Grid item md={2} sm={5} xs={10} >
          <Grow in={true} timeout={2000}>
            <Button color='primary' variant="contained" component={Link} to='/contact' className={classes.button} fullWidth size="small">
              CONTACT US
            </Button>
          </Grow>
        </Grid>
        <Grid item md={2} sm={5} xs={10} >
          <Grow in={true} timeout={2500}>
            <Button color='primary' variant="contained" component={Link} to='/gallery' className={classes.button} fullWidth size="small">
              image gallery
            </Button>
          </Grow>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Header;