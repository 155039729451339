import React, { FunctionComponent, useEffect, useState } from "react"
// import { useStaticQuery, graphql } from "gatsby";
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import theme from '../theme';
import Header from "./header";
import Footer from './Footer';
import { WindowLocation } from "../types/PageProps";

const primary = theme.palette.primary.main;
const background = theme.palette.background.default;


const useStyles = makeStyles({
  container: {
    background: `linear-gradient(360deg, ${primary} 30%, ${background} 100%)`,
    fontFamily: 'Raleway',
  },
});

const Layout: FunctionComponent<{ location?: WindowLocation<unknown> }> = ({ children, location }) => {
  const classes = useStyles();
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Container maxWidth="xl" className={classes.container}>
          <Header />
            {children}
          <Footer location={location} />
        </Container>
      </ThemeProvider>
    </>
  );
};

export default Layout